import React, { ReactElement, ReactNode } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import DocumentLayout from './components/DocumentLayout';
import DocumentSelector from './components/DocumentSelector';
import ExtractedData from './components/ExtractedData';
import GeographicSegm from './components/GeographicSegm';
import HeaderMenu from './components/HeaderMenu';

const Page = ({ children }: { children: ReactNode }) => (
  <>
    <HeaderMenu />
    <div
      className="flex flex-col mt-28 mx-2"
      style={{ height: 'calc(100vh - 120px)' }}
    >
      <DocumentSelector />
      <div className="flex-grow">{children}</div>
    </div>
  </>
);

function App(): ReactElement {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/document-layout/:docID">
            <Page>
              <DocumentLayout />
            </Page>
          </Route>
          <Route path="/geographic-segm/:docID">
            <Page>
              <GeographicSegm />
            </Page>
          </Route>
          <Route path="/extracted-data/:docID">
            <Page>
              <ExtractedData />
            </Page>
          </Route>
          <Route match="*">
            <Redirect to="/document-layout/0" />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
