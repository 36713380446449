import {
  Document,
  Highlight,
  pdfjs,
  TooltipHover,
} from '@datacie/react-pdf-fc';
import * as React from 'react';
import { useCallback, useState } from 'react';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  pdfSource: any;
  highlights: Highlight[];
  onHighlightClick: (id: string) => void;
  pageNumber?: number;
};

export default ({
  pdfSource,
  highlights,
  onHighlightClick,
  pageNumber,
}: Props): JSX.Element => {
  const [selectedHighlights, setSelectedHighlights] = useState<string[]>([]);

  // Custom styling of highlights
  const styleHighlight = useCallback((highlight): React.CSSProperties => {
    const {
      comment: { tags },
    } = highlight;

    if (tags.includes('Table')) {
      return { backgroundColor: '#34B88B' };
    }
    if (tags.includes('Paragraph')) {
      return { backgroundColor: '#338399' };
    }
    if (tags.includes('Heading')) {
      return { backgroundColor: '#36449A' };
    }
    if (tags.includes('Other')) {
      return { backgroundColor: '#A0AEC0' };
    }

    return { backgroundColor: 'blue' };
  }, []);

  // Trigger selection
  const dispatchSelection = (s: any) => {
    if (s.type === 'toggle' && !selectedHighlights.includes(s.id)) {
      setSelectedHighlights([s.id]);

      onHighlightClick(s.id);
    } else if (s.type === 'clear') {
      setSelectedHighlights([]);
    } else if (s.type === 'force-select') {
      setSelectedHighlights(s.ids);
    } else {
      setSelectedHighlights(selectedHighlights.filter((i) => i !== s.id));
    }
  };

  return (
    <Document
      pageNo={pageNumber}
      pdfSource={pdfSource}
      selectedHighlights={selectedHighlights}
      dispatchSelection={dispatchSelection}
      highlights={highlights}
      TooltipHover={TooltipHover}
      styleHighlight={styleHighlight}
    />
  );
};
