const files = [
  'AALBERTS annual_report 2019.json',
  'ACCOR annual_report 2019.json',
  'ADDTECH_B annual_report 2019.json',
  'AF_POYRY_B annual_report 2019.json',
  'ALCON annual_report 2019.json',
  'ALS_Limited annual_report 2019.json',
  'AMS_AG annual_report 2019.json',
  'ARYZTA annual_report 2019.json',
  'ASSICURAZIONI_GENERALI annual_report 2019.json',
  'AUTO_TRADER_GROUP annual_report 2019.json',
  'AstraZeneca_AR_2018.json',
  'BAYER annual_report 2019.json',
  'BELIMO_HOLDING annual_report 2019.json',
  'BNP_PARIBAS annual_report 2019.json',
  'BOLIDEN annual_report 2019.json',
  'BOUYGUES 2019.json',
  'CEZ annual_report 2019.json',
  'CK_Property_Holdings annual_report 2019.json',
  'CLP_Holdings_Ltd annual_report 2019.json',
  'COCA-COLA_HBC annual_report 2019.json',
  'COMMERZBANK annual_report 2019.json',
  'CONTINENTAL annual_report 2019.json',
  'Cochlear_Ltd annual_report 2019.json',
  'DAVIDE_CAMPARI annual_report 2019.json',
  'DIALOG_SEMICON annual_report 2019.json',
  'DISTRIBUIDORA_INT_ALIMENTACION annual_report 2018.json',
  'Dai-ichi_Life_Holdings annual_report 2019.json',
  'EMERA annual_report 2019.json',
  'EQUINOR annual_report 2019.json',
  'EURONEXT 2018.json',
  'EURONEXT 2019.json',
  'EVONIK_INDUSTRIES annual_report 2019.json',
  'EXPERIAN annual_report 2019.json',
  'FERGUSON_PLC annual_report 2019.json',
  'FERROVIAL annual_report 2019.json',
  'First_Quantum_Minerals_Ltd annual_report 2019.json',
  'Fortescue_Metals_Group_Ltd annual_report 2019.json',
  'Fresenius 2019.json',
  'GERRESHEIMER annual_report 2019.json',
  'GETINGE_B annual_report 2019.json',
  'GLENCORE_PLC annual_report 2019.json',
  'HEIDELBERGCEMENT annual_report 2019.json',
  'HEXAGON_B annual_report 2019.json',
  'HOCHTIEF annual_report 2019.json',
  'HOMESERVE annual_report 2019.json',
  'Hongkong_Land_Holdings_Ltd annual_report 2019.json',
  'ICA_GRUPPEN annual_report 2019.json',
  'IMI annual_report 2019.json',
  'INGENICO annual_report 2019.json',
  'IPSEN annual_report 2019.json',
  'Industria_de_Diseno_Textil_SA annual_report 2019.json',
  'JOHNSON_MATTHEY annual_report 2019.json',
  'KION_GROUP annual_report 2019.json',
  'KONE_B annual_report 2019.json',
  'Keyence_Corp annual_report 2019.json',
  'LOREAL_2019_Universal_Registration_Document_en.json',
  'MAPLETREE_LOGISTTRUST annual_report 2019.json',
  'MERLIN_ENTERTAINMENTS annual_report 2019.json',
  'METSO annual_report 2019.json',
  'MINERAL_RESOURCES annual_report 2019.json',
  'MINTH_GROUP annual_report 2018.json',
  'MONOTARO annual_report 2019.json',
  'MORPHOSYS annual_report 2019.json',
  'Mitsui_Chemicals_Inc annual_report 2019.json',
  'Murata_Manufacturing_Co_Ltd annual_report 2019.json',
  'NMC_HEALTH annual_report 2019.json',
  'NORSK_HYDRO annual_report 2019.json',
  'NUTRIEN annual_report 2019.json',
  'New_World_Development_Co_Ltd annual_report 2019.json',
  'Nisshin_Seifun_Group_Inc annual_report 2019.json',
  'OMV annual_report 2019.json',
  'OTE annual_report 2019.json',
  'OZ_Minerals_Ltd annual_report 2019.json',
  'Orica_Ltd annual_report 2019.json',
  'PEARSON annual_report 2019.json',
  'POLYMETAL_INTERNATIONAL annual_report 2019.json',
  'PUMA annual_report 2019.json',
  'QIAGEN annual_report 2019.json',
  'QINETIQ_GRP annual_report 2019.json',
  'RECORDATI annual_report 2019.json',
  'REPSOL annual_report 2019.json',
  'RIO_TINTO annual_report 2019.json',
  'ROCKWOOL_B annual_report 2019.json',
  'SANDVIK annual_report 2019.json',
  'SEADRILL annual_report 2019.json',
  'SECURITAS_B annual_report 2019.json',
  'SIG_COMBIBLOC_GROUP_AG annual_report 2019.json',
  'SOLVAY annual_report 2019.json',
  'SWIRE_PROPERTIES annual_report 2019.json',
  'Saputo_Inc annual_report 2019.json',
  'Shangri-La_Asia_Ltd annual_report 2019.json',
  'Sharp_Corp annual_report 2019.json',
  'Stanley_Electric_Co_Ltd annual_report 2019.json',
  'TAKEAWAYCOM_HOLDING_BV annual_report 2019.json',
  'TATE_&_LYLE annual_report 2019.json',
  'TERNA annual_report 2019.json',
  'UDG_HEALTHCARE_PUBLIC annual_report 2019.json',
  'WIHLBORGS_FASTIGHETER annual_report 2019.json',
  'WOLTERS_KLUWER annual_report 2019.json',
  'WPP annual_report 2019.json',
  'Wolters-Kluwer-2019.json',
  'abb 2019.json',
  'airbus 2018.json',
  'atos 2019.json',
  'aviva 2016.json',
  'aviva 2017.json',
  'aviva 2019.json',
  'basf 2016.json',
  'basf 2017.json',
  'basf 2018.json',
  'canon 2018.json',
  'canon 2019.json',
  'carrefour 2017.json',
  'carrefour 2019.json',
  'clariant 2016.json',
  'clariant financial report 2019.json',
  'compass 2019.json',
  'credit agricole 2016.json',
  'daimler-ir-annual-report-2017.json',
  'daimler-ir-annual-report-2018.json',
  'daimler-ir-annual-report-2019-incl-combined-management-report-daimler-ag.json',
  'daimler-ir-annualreport-2016.json',
  'db 2016.json',
  'db 2017.json',
  'db 2018.json',
  'db 2019.json',
  'deutsche telekom 2016.json',
  'deutsche telekom 2017.json',
  'deutsche telekom 2018.json',
  'deutsche telekom 2019.json',
  'deutschepost 2017.json',
  'deutschepost 2018.json',
  'deutschepost 2019.json',
  'endesa financial report 2019.json',
  'eni 2018.json',
  'eni 2019.json',
  'exor 2019.json',
  'ferrari 2019.json',
  'fortum 2019.json',
  'fresenius 2019.json',
  'galp energia 2016.json',
  'galp energia 2018.json',
  'generali 2018.json',
  'generali 2019.json',
  'glencore 2016.json',
  'glencore 2017.json',
  'glencore 2018.json',
  'glencore 2019.json',
  'heineken holding 2017.json',
  'honda 2016.json',
  'honda 2018.json',
  'hsbc 2016.json',
  'hsbc 2017.json',
  'huhtamaki 2019.json',
  'iberdola 2019.json',
  'ingenico 2019.json',
  'intesa 2019.json',
  'lafargeholcim 2016.json',
  'lafargeholcim 2018.json',
  'lafargeholcim 2019.json',
  'linde 2019.json',
  'lonza 2018.json',
  'lufthansa 2017.json',
  'lufthansa 2018.json',
  'mapfre 2019.json',
  'merck 2019.json',
  'metso 2019.json',
  'michelin 2017.json',
  'nestle 2016.json',
  'novartis 2016.json',
  'novartis 2018.json',
  'oci 2019.json',
  'orange 2019.json',
  'philips 2016.json',
  'philips 2018.json',
  'philips 2019.json',
  'ricoh 2019.json',
  'sanofi 2017.json',
  'sanofi 2019.json',
  'se 2016.json',
  'se 2018.json',
  'sharp corporation 2018.json',
  'siemens 2017.json',
  'siemens 2018.json',
  'sika 2019.json',
  'societe general 2019.json',
  'stmicroelectronics 2019.json',
  'swisscom 2019.json',
  'swissre 2017.json',
  'swissre 2018.json',
  'swissre 2019.json',
  'syngenta 2017.json',
  'syngenta 2018.json',
  'syngenta 2019.json',
  'total 2019.json',
  'ucb 2019.json',
  'vodafone 2019.json',
  'voestalpine 2019-20.json',
  'volkswagen 2016.json',
  'volkswagen 2017.json',
  'volkswagen 2018.json',
  'volkswagen 2019.json',
  'volvo 2016.json',
  'volvo 2017.json',
  'volvo 2018.json',
  'volvo 2019.json',
  'vopak 2019.json',
  'worldline-universal-registration-document-2019.json',
];
export default files;
