import React, { useEffect, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'src/types';
import styled from 'styled-components';
import 'react-datasheet/lib/react-datasheet.css';
import { getExtractedUrl, getFiles } from 'src/helpers/load_files';

const files = getFiles();

type Props = {
  regions: string[];
  values: number[];
  percentages: number[];
  currencies: string[];
  units: string[];
};

type Row = {
  currency: string;
  percentage: string;
  region: string;
  region_as_reported: string;
  subset_of?: string;
  total_if_in_table: number;
  units: string;
  value: number;
};

type InputData = {
  year: number;
  page_no: number;
  table_no: number;
  description: string;
  rows: Row[];
};

const formatPct = ({ value }: { value: string }) => {
  return (parseFloat(value) * 100).toFixed(2);
};

const Table = ({
  regions,
  percentages,
  values,
  units,
  currencies,
}: Props): JSX.Element => {
  const cells = [];

  const cellParams = { readOnly: true };

  cells.push([
    { value: 'Region', ...cellParams },
    { value: 'Value', ...cellParams },
    { value: '%', ...cellParams },
    { value: 'Units', ...cellParams },
    { value: 'Currency', ...cellParams },
  ]);

  regions.forEach((value, i) => {
    cells.push([
      { value, ...cellParams },
      { value: values[i], ...cellParams },
      {
        value: percentages[i],
        valueViewer: formatPct,
        ...cellParams,
      },
      { value: units[i], ...cellParams },
      { value: currencies[i], ...cellParams },
    ]);
  });

  return <ReactDataSheet data={cells} valueRenderer={(cell) => cell.value} />;
};

const StyledDiv = styled.div`
  .cell {
    padding: 4px !important;
  }
`;

export default (): JSX.Element => {
  const [extractedData, setExtractedData] = useState<InputData[]>();

  const { docID } = useParams<RouteParams>();

  useEffect(() => {
    setExtractedData(undefined);

    if (docID) {
      const id = parseInt(docID, 10);

      const extractedUrl = getExtractedUrl(files[id]);

      fetch(extractedUrl)
        .then((data) => data.json())
        .then((data) => {
          setExtractedData(data);
        });
    }
  }, [docID]);

  return (
    <div className="flex align-center justify-center space-x-4 flex-wrap">
      {extractedData &&
        extractedData.map(({ year, rows }) => (
          <div key={year} className="space-y-2 flex flex-col items-center">
            <div>{year}</div>
            <div className="bg-white rounded p-6">
              <StyledDiv>
                <Table
                  regions={rows.map(({ region }) => region)}
                  percentages={rows.map(({ percentage }) =>
                    parseFloat(percentage)
                  )}
                  values={rows.map(({ value }) => value)}
                  units={rows.map(({ units }) => units)}
                  currencies={rows.map(({ currency }) => currency)}
                />
              </StyledDiv>
            </div>
          </div>
        ))}
    </div>
  );
};
