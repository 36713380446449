import { Highlight } from '@datacie/react-pdf-fc';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPdfUrl, getProcessedUrl, getFiles } from 'src/helpers/load_files';
import { JSONDocument, JSONElement, RouteParams, TableRow } from 'src/types';
import PdfViewer from '../PdfViewer';
import Table from '../Table';

const files = getFiles();

type Table = { rows: TableRow[] };

const formatJson = (jsonDocument: JSONDocument) => {
  const highlights: Highlight[] = [];

  Object.entries(jsonDocument).forEach(([page, labels]) => {
    labels.forEach(({ box, label }) => {
      const boundingRect = {
        x: box.x0,
        y: box.y0,
        width: box.x1 - box.x0,
        height: box.y1 - box.y0,
      };

      const position = {
        boundingRect,
        rects: [boundingRect],
        pageNumber: parseInt(page, 10) + 1,
      };

      let tags: string[];
      if (!label) {
        tags = ['Table'];
      } else {
        tags = [label.label, label.confidence.toString()];
      }

      highlights.push({
        position,
        content: {},
        comment: { text: '', tags },
        id: highlights.length.toString(),
      });
    });
  });

  return highlights;
};

export default (): JSX.Element => {
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [rawData, setRawData] = useState<JSONDocument>();
  const [selectedTable, setSelectedTable] = useState<Table>();
  const [pdfSource, setPdfSource] = useState<string>();

  const { docID } = useParams<RouteParams>();

  useEffect(() => {});

  useEffect(() => {
    setRawData(undefined);
    setSelectedTable(undefined);
    setPdfSource(undefined);
    setHighlights([]);

    if (docID) {
      const id = parseInt(docID, 10);
      const jsonUrl = getProcessedUrl(files[id]);
      const pdfUrl = getPdfUrl(files[id].replace('.json', '.pdf'));

      // Set local state of pdf
      setPdfSource(pdfUrl);

      fetch(jsonUrl)
        .then((data) => data.json())
        .then((data) => {
          setRawData(data);
          const formattedHighlights = formatJson(data);
          setHighlights(formattedHighlights);
        })
        .catch(() => {});
    }
  }, [docID]);

  const onHighlightClick = (id: string) => {
    const table = highlights.filter(
      (h) => h.id === id && h.comment.tags.includes('Table')
    );

    if (table.length !== 0 && rawData) {
      const page = table[0].position.pageNumber;
      const { boundingRect } = table[0].position;
      const matched = rawData[page - 1].filter(
        ({ box }: JSONElement) =>
          Math.abs(box.x0 - boundingRect.x) +
            Math.abs(box.y0 - boundingRect.y) <
          0.01
      );

      setSelectedTable(matched[0] as Table);
    } else {
      setSelectedTable(undefined);
    }
  };

  return (
    <div className="grid grid-cols-2 h-full">
      <div className="flex-shrink-0 p-2 bg-white rounded h-full">
        <PdfViewer
          pdfSource={pdfSource}
          highlights={highlights}
          onHighlightClick={onHighlightClick}
        />
      </div>
      {selectedTable && (
        <div className="m-4 p-4 flex-shrink overflow-auto place-self-center bg-white rounded">
          <Table data={selectedTable} />
        </div>
      )}
    </div>
  );
};
