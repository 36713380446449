import { HeaderMenu, Logo } from '@datacie/ui';
import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import path from 'path';
import { RouteParams } from 'src/types';

const links = [
  { link: 'document-layout', name: 'Document layout' },
  { link: 'geographic-segm', name: 'Geographic Segmentation' },
  { link: 'extracted-data', name: 'Extracted data' },
];

export default (): JSX.Element => {
  const { docID } = useParams<RouteParams>();
  const history = useHistory();

  return (
    <HeaderMenu
      menuItemsLinks={links.map(({ link, name }) => (
        <div
          className={
            history.location.pathname.includes(link)
              ? 'border-b-2 border-darkturquoise-500'
              : ''
          }
        >
          <Link to={path.join('/', link, docID)}>{name}</Link>
        </div>
      ))}
      logo={Logo.Datacie}
      theme={{
        variant: 'gradient-cta',
      }}
      isFixed
    />
  );
};
