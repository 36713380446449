import { Select } from '@datacie/ui';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import path from 'path';
import { RouteParams } from 'src/types';
import { getFiles } from '../../helpers/load_files';

const files = getFiles();

const options = files.map((filename: string) => ({ label: `${filename}.pdf` }));

export default (): JSX.Element => {
  const history = useHistory();

  const { docID } = useParams<RouteParams>();

  // Push the id in the url
  const onSelect = (label: string) => {
    const newDocID = options
      .map(({ label: lab }: { label: string }) => lab)
      .indexOf(label);
    const rootPath = history.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');
    history.push(path.join(rootPath, newDocID.toString()));
  };

  return (
    <div className="w-48 m-4 z-20">
      <Select
        options={options}
        label="Select document"
        selected={docID ? [options[parseInt(docID, 10)].label] : []}
        onSelect={onSelect}
        multi={false}
      />
    </div>
  );
};
