import { Highlight } from '@datacie/react-pdf-fc';
import { Gauge } from '@datacie/ui';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatJson, matchTable } from 'src/components/GeographicSegm/utils';
import {
  getFiles,
  getProcessedUrl,
  getTablesUrl,
} from 'src/helpers/load_files';
import { JSONBox, RouteParams, TableRow } from 'src/types';
import { getPdfUrl } from '../../helpers/load_files';
import PdfViewer from '../PdfViewer';
import Table from '../Table';

const files = getFiles();

export type Table = { rows: TableRow[] };

type InputTableData = {
  page: string;
  table_position: JSONBox;
};

export default (): JSX.Element => {
  const [highlights, setHighlights] = useState<Highlight[]>([]);
  const [rawLabels, setRawLabels] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number | undefined>(2);
  const [tablesData, setTablesData] = useState<InputTableData[]>();
  const [pdfSource, setPdfSource] = useState<string>();
  const [height, setHeight] = useState(700);

  const { docID } = useParams<RouteParams>();

  useEffect(() => {
    if (pageNumber) {
      setPageNumber(undefined);
    }
  }, [pageNumber]);

  useEffect(() => {});

  useEffect(() => {
    setPdfSource(undefined);
    setRawLabels(undefined);
    setTablesData([]);
    setHighlights([]);

    if (docID) {
      const file = files[parseInt(docID, 10)];

      const jsonUrl = getProcessedUrl(file);
      const pdfUrl = getPdfUrl(file.replace('.json', '.pdf'));

      const tablesUrl = getTablesUrl(file);

      // Set local state of pdf
      setPdfSource(pdfUrl);

      fetch(jsonUrl)
        .then((data) => data.json())
        .then((data) => {
          setRawLabels(data);
          const formattedHighlights = formatJson(data);
          setHighlights(formattedHighlights);
        })
        .catch(() => {});

      fetch(tablesUrl)
        .then((data) => data.json())
        .then((data) => {
          setTablesData(data);
        })
        .catch(() => {});
    }
  }, [docID]);

  const onHighlightClick = () => {};

  const tables = useMemo(() => {
    if (!tablesData || !rawLabels) {
      return [];
    }

    const existingTable = new Set();
    return tablesData
      .reduce((acc: InputTableData[], table) => {
        const tableString = JSON.stringify(table);
        if (existingTable.has(tableString)) {
          return acc;
        }
        existingTable.add(tableString);

        acc.push(table);
        return acc;
      }, [])
      .sort((a: InputTableData, b: InputTableData) =>
        parseInt(a.page, 10) < parseInt(b.page, 10) ? -1 : 1
      )
      .map(({ page, table_position }: InputTableData) => {
        const pageNo = parseInt(page, 10) + 1;
        const table = matchTable(rawLabels[page], table_position);

        return table ? (
          <div
            className="flex items-center flex-col hover:shadow p-2 cursor-pointer rounded"
            key={JSON.stringify(table_position)}
            onClick={() => setPageNumber(pageNo)}
            role="none"
          >
            <div className="m-2 flex justify-around w-full">
              <span>{`p.${pageNo}`}</span>
              {/* <span>{table.description}</span> */}
              <Gauge pct={0.95} />
            </div>
            <div className="overflow-auto max-w-full max-h-56">
              <Table data={table} />
            </div>
          </div>
        ) : null;
      });
  }, [tablesData, rawLabels]);

  useEffect(() => {
    const setHeightAccordingToWindow = () => {
      setHeight(window.innerHeight - 220);
    };

    const handleResize = debounce(() => {
      setHeightAccordingToWindow();
    }, 300);
    window.addEventListener('resize', handleResize);

    setHeightAccordingToWindow();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setHeight]);

  return (
    <div className="grid grid-cols-2 space-x-4" style={{ height }}>
      <div className="flex-shrink-0 p-2 bg-white rounded">
        <PdfViewer
          pageNumber={pageNumber}
          pdfSource={pdfSource}
          highlights={highlights}
          onHighlightClick={onHighlightClick}
        />
      </div>
      <div className="bg-white p-4 rounded flex flex-col overflow-auto">
        {/* The overflow should appear at this level, but I can't control the overflow */}
        {/* <div className="overflow-auto max-h-full">{tables}</div> */}
        {tables}
      </div>
    </div>
  );
};
