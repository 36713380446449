import React, { useMemo } from 'react';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import 'src/assets/tailwind-generated.css';
import { TableRow } from 'src/types';

type Props = {
  data: { rows: TableRow[] };
};

export default ({ data }: Props): JSX.Element => {
  const grid = useMemo(
    () =>
      data.rows.map(({ cells }) =>
        cells.map(({ text }) => ({
          value: text,
          readOnly: true,
          // className: 'w-28 ',
        }))
      ),
    [data]
  );

  return <ReactDataSheet data={grid} valueRenderer={(cell) => cell.value} />;
};
