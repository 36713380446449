import path from 'path';
import files from 'src/assets/files';

export const getPdfUrl = (file: string): string =>
  new URL(path.join('files', file), process.env.REACT_APP_DATA_URL ?? '').href
    .split(' ')
    .join('+');

export const getExtractedUrl = (file: string): string =>
  new URL(path.join('output', file), process.env.REACT_APP_DATA_URL ?? '').href
    .split(' ')
    .join('+');

export const getProcessedUrl = (file: string): string =>
  new URL(
    path.join('processed', file),
    process.env.REACT_APP_DATA_URL ?? ''
  ).href
    .split(' ')
    .join('+');

export const getTablesUrl = (file: string): string =>
  new URL(
    path.join('extracted', file),
    process.env.REACT_APP_DATA_URL ?? ''
  ).href
    .split(' ')
    .join('+');

export const getFiles = (): string[] => {
  return files.reduce((acc: string[], filename: string) => {
    if (!acc.includes(filename)) {
      acc.push(filename);
    }

    return acc;
  }, []);
};
