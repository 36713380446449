import { Highlight } from '@datacie/react-pdf-fc';
import { JSONBox, JSONDocument } from 'src/types';
import { Table } from './GeographicSegm';

export const formatJson = (jsonDocument: JSONDocument): Highlight[] => {
  const highlights: Highlight[] = [];

  Object.entries(jsonDocument).forEach(([page, labels]) => {
    labels.forEach(({ box, label }) => {
      const boundingRect = {
        x: box.x0,
        y: box.y0,
        width: box.x1 - box.x0,
        height: box.y1 - box.y0,
      };

      const position = {
        boundingRect,
        rects: [boundingRect],
        pageNumber: parseInt(page, 10) + 1,
      };

      let tags: string[];
      if (!label) {
        tags = ['Table'];
      } else {
        tags = [label.label, label.confidence.toString()];
        // Add this return to avoid highlight of labels that are not tables
        return;
      }

      highlights.push({
        position,
        content: {},
        comment: { text: '', tags },
        id: highlights.length.toString(),
      });
    });
  });

  return highlights;
};

export const matchTable = (rawLabels: any[], box: JSONBox): false | Table => {
  const filtered = rawLabels.filter(({ box: labelBox, rows }) => {
    if (!rows) {
      return false;
    }

    return (
      Math.abs(labelBox.x0 - box.x0) < 0.01 &&
      Math.abs(labelBox.y0 - box.y0) < 0.01 &&
      Math.abs(labelBox.x1 - box.x1) < 0.01 &&
      Math.abs(labelBox.y1 - box.y1) < 0.01
    );
  });

  if (filtered) {
    return filtered[0];
  }

  return false;
};
